import { takeEvery, call, put, select } from 'redux-saga/effects'

import { addError } from '../message'
import { setVariableData, setVariableDataLoading } from './renderer.actions'
import { RendererActionTypes, RenderMode } from './renderer.model'
import { ApiResponse } from 'apisauce'
import {
  SelectedMedicalEventDocumentWrapper,
  selectedMedicalEventDocumentSelector,
} from '../ui/medicalEvents'
import { MedicalEventDocumentType } from '../../model/MedicalEvent'

import { fetchDocumentVariableData } from './api'
import { VariableDataSerializer, VariablesDataWithError } from '@follow/cdk'
import { queryClient } from '../../App'
import { documentKeys } from '../../hooks/queries/documents/documents.keys'

export function* getVariableDataWorker() {
  const selectedDocument: SelectedMedicalEventDocumentWrapper | null = yield select(
    selectedMedicalEventDocumentSelector,
  )
  if (selectedDocument?.type !== MedicalEventDocumentType.FW_DOCUMENT) {
    return
  }

  const documentId = selectedDocument.item.id
  yield put(setVariableDataLoading(true))
  const variableData: ApiResponse<VariablesDataWithError> = yield call(
    fetchDocumentVariableData,
    documentId,
    RenderMode.PREVIEW,
  )
  yield put(setVariableDataLoading(false))

  if (variableData.ok && variableData.data) {
    const deserialized = VariableDataSerializer.deserialize(variableData.data.variables)
    yield put(setVariableData(deserialized))
    queryClient.invalidateQueries({ queryKey: documentKeys.variable_data(documentId) })
  } else {
    yield put(
      addError(
        'Erreur pendant le chargement du document',
        "Le contenu des variables n'a pas pu être récupéré",
      ),
    )
    console.error('[Variable Data]', variableData)
  }
}

export function* getVariableDataWatcher() {
  yield takeEvery(RendererActionTypes.GET_VARIABLE_DATA, getVariableDataWorker)
}
