import { useMutation, useQueryClient } from '@tanstack/react-query'
import { putUser } from '../../../../data/admin'
import { LightUser, User } from '../../../../model/User'
import { UserFriendlyError } from '../../../../model/Error'
import { addValid } from '../../../../store/message'
import { useActionDispatch } from '../../../utils'
import { userKeys } from './users.keys'
import { checkIfPagesContainItem } from '../../../../misc/query.utilities'

interface UserMutateVariables {
  userId: string
  variables: Partial<User>
}

export const usePutUser = () => {
  const valid = useActionDispatch(addValid)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ userId, variables }: UserMutateVariables) => {
      const { data, ok } = await putUser(userId, variables)
      if (!data || !ok) {
        throw new UserFriendlyError('error', "La mise à jour de l'utilisateur a échoué")
      }
      return data
    },

    onSuccess: (user) => {
      valid('Utilisateur mis à jour')
      queryClient.refetchQueries({
        queryKey: userKeys.all,
        predicate: (query) => checkIfPagesContainItem<LightUser>(query.state.data, user.id),
      })
      queryClient.setQueryData(userKeys.detail(user.id), user)
    },
  })
}
