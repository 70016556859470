import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'

import { MedicalEventFiltersProps } from './MedicalEventFilters.model'

import { MedicalEventContentTypeIcon, MedicalEventDocumentType } from '../../../model/MedicalEvent'
import { isImageExtension, isPrintableFileExtension } from '../../../misc/files.utilities'
import { Heading, IconButton, IconChip, MenuChip, ValidationModal } from '../../shared'

import { FiltersWrapper } from '../../shared/filters/FiltersWrapper'
import { ContentFilters } from './ContentFilters'
import { CustomFile, FileKind } from '../../../model/File'

import styles from './MedicalEventFilters.module.scss'
import { AddContentButton } from '../../shared/buttons/AddContentButton'
import { useCurrentPatient, useWidthObserver } from '../../../hooks/utils'
import { breakMedium } from '../../../misc/responsive'
import { DocumentCategoryColor } from '../../../model/DocumentCategory'
import { formatPrescriptionPrice, isPrescription } from '../../../misc/documents.utilities'
import { DocumentInstanceListItem } from '../../../model/DocumentInstance'
import { FwDocumentType } from '../../../model/Document'
import { EfficienceDmpUploadModal } from '../..//dmp/EfficienceDmpUploadModal'
import { useLocation } from 'react-router'
import { usePrintDocuments, useDownloadDocuments } from '../../../hooks/queries/document'
import { isDefined } from '../../../misc/functions.utilities'

export const MedicalEventFilters: FunctionComponent<MedicalEventFiltersProps> = ({
  initializeFromUrl,
  lockDocument,
  medicalEvent,
  medicalEventDeleteDocument,
  medicalEventDocumentFilter,
  medicalEventSelectedDocument,
  selectMedicalEventDocument,
  updateDocument,
  renewPrescription,
  files,
  addFilesToMedicalEvent,
  deleteFromDMP,
  userEnabledFeatures,
  uploadToDMP,
}) => {
  const { currentPatient: patient } = useCurrentPatient()
  const location = useLocation()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const isLowerThanBreakpoint = useWidthObserver(breakMedium)
  const [currentLocation] = useState(location)
  const [currentMedicalEvent] = useState(medicalEvent)
  const [documentToLock, setDocumentToLock] = useState<undefined | DocumentInstanceListItem>()
  const [displayRenewModal, setDisplayRenewModal] = useState<boolean>(false)
  const [pendingDmpUpload, setPendingDmpUpload] = useState<DocumentInstanceListItem>()
  const print = usePrintDocuments()
  const download = useDownloadDocuments()

  const selectedDocId =
    medicalEventDocumentFilter &&
    medicalEventDocumentFilter.medicalEventDocumentType === MedicalEventDocumentType.FW_DOCUMENT
      ? medicalEventDocumentFilter.id
      : null

  const selectedFileId =
    medicalEventDocumentFilter &&
    medicalEventDocumentFilter.medicalEventDocumentType === MedicalEventDocumentType.FILE
      ? medicalEventDocumentFilter.id
      : null

  const selectObservations =
    !!medicalEventDocumentFilter &&
    medicalEventDocumentFilter.medicalEventDocumentType === MedicalEventDocumentType.OBSERVATIONS

  const docsLength = medicalEvent.documents.length + medicalEvent.files.length

  const displayAddDocumentsButton =
    medicalEvent.isEditable &&
    isDefined(medicalEventDocumentFilter?.medicalEventDocumentType) &&
    medicalEventDocumentFilter?.medicalEventDocumentType !== MedicalEventDocumentType.ADDING

  // Query params can be ?docId={docId}&fileId={fileId}
  useEffect(() => {
    initializeFromUrl(currentLocation, currentMedicalEvent)
  }, [initializeFromUrl, currentLocation, currentMedicalEvent])

  const handleSelectMedicalEventDocument =
    (medicalEventDocumentType: MedicalEventDocumentType, id: number) => () => {
      if (id === selectedDocId) {
        return
      }
      selectMedicalEventDocument({
        medicalEventDocumentType,
        id,
        resetSelectedQuestionnaire: true,
      })
    }

  const handleEditDocumentTitle = (documentId: number, title: string, type: FwDocumentType) => {
    updateDocument(documentId, title, MedicalEventDocumentType.FW_DOCUMENT, type)
  }

  const handleEditFileTitle = (fileId: number, visibleName: string) => {
    updateDocument(fileId, visibleName, MedicalEventDocumentType.FILE)
  }

  const handleDeleteDocument = (id: number) => {
    if (window.confirm('Êtes vous sûr(e) de vouloir supprimer le document ?')) {
      medicalEventDeleteDocument(id, MedicalEventDocumentType.FW_DOCUMENT)
    }
  }

  const handleDeleteFile = (id: number) => {
    if (window.confirm('Êtes vous sûr(e) de vouloir supprimer le fichier ?')) {
      medicalEventDeleteDocument(id, MedicalEventDocumentType.FILE)
    }
  }

  const handleDownloadFile = useCallback(
    (file: CustomFile) => download({ files: [file] }),
    [download],
  )

  const handlePrintFile = useCallback((file: CustomFile) => print({ files: [file] }), [print])

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      const files = Array.from(event.target.files) as File[]
      addFilesToMedicalEvent(files)
    }
  }

  const handleAddDocument = useCallback(() => {
    selectMedicalEventDocument({ medicalEventDocumentType: MedicalEventDocumentType.ADDING })
  }, [selectMedicalEventDocument])

  const handleLockDocument = () => {
    if (documentToLock) {
      lockDocument(documentToLock?.id, !documentToLock?.locked)
    }
    setDocumentToLock(undefined)
  }

  const displayQuestionnaireFilter =
    medicalEventSelectedDocument?.type === MedicalEventDocumentType.FW_DOCUMENT &&
    medicalEventSelectedDocument.item.type === 'farte'

  return (
    <>
      <div className={styles.container}>
        <Heading size={5} theme="dark" weight="regular">
          <span className={styles.label}>Documents</span>
          <span className={styles.counter}>{docsLength}</span>
        </Heading>
        <div className={styles.content}>
          <FiltersWrapper variance="variant1">
            <IconChip
              onClick={handleSelectMedicalEventDocument(
                MedicalEventDocumentType.OBSERVATIONS,
                medicalEvent.id,
              )}
              selected={selectObservations}
              icon="editAlt"
              label="Observations"
              testId="button-chip-observations"
              borderColor={DocumentCategoryColor['other']}
            />
            <div className={styles.separator} />
            {medicalEvent.documents.map((documentInstance, index) => (
              <MenuChip
                key={index}
                onClick={handleSelectMedicalEventDocument(
                  MedicalEventDocumentType.FW_DOCUMENT,
                  documentInstance.id,
                )}
                testId={`button-chip-document-${documentInstance.id}`}
                collapsed={documentInstance.id !== selectedDocId}
                selected={documentInstance.id === selectedDocId}
                downloadConfig={
                  userEnabledFeatures?.cda
                    ? [
                        {
                          label: 'PDF',
                          onClick: () =>
                            download({
                              documents: [documentInstance],
                            }),
                        },
                        {
                          label: 'CDA',
                          onClick: () =>
                            download({
                              documents: [documentInstance],
                              format: 'cda',
                            }),
                        },
                      ]
                    : () =>
                        download({
                          documents: [documentInstance],
                        })
                }
                onPrint={() =>
                  print({
                    documents: [documentInstance],
                  })
                }
                onSendToDMP={
                  userEnabledFeatures?.dmpInApp
                    ? () => setPendingDmpUpload(documentInstance)
                    : undefined
                }
                onDeleteFromDMP={
                  userEnabledFeatures?.dmpInApp && documentInstance.dmpDocumentId
                    ? () => {
                        if (patient?.inseeNumber && documentInstance.dmpDocumentId)
                          deleteFromDMP(
                            documentInstance.id,
                            documentInstance.dmpDocumentId,
                            patient.inseeNumber,
                          )
                      }
                    : undefined
                }
                onDelete={
                  medicalEvent.isDeletable
                    ? () => handleDeleteDocument(documentInstance.id)
                    : undefined
                }
                onLock={() => setDocumentToLock(documentInstance)}
                onTitleSave={
                  !documentInstance.locked && medicalEvent.isEditable
                    ? (title) =>
                        handleEditDocumentTitle(documentInstance.id, title, documentInstance.type)
                    : undefined
                }
                icon={MedicalEventContentTypeIcon[documentInstance.category]}
                label={documentInstance.title}
                onRenewal={
                  documentInstance.type === 'farte' && isPrescription(documentInstance.category)
                    ? () => setDisplayRenewModal(true)
                    : undefined
                }
                sublabel={
                  documentInstance.price
                    ? formatPrescriptionPrice(documentInstance.price)
                    : undefined
                }
                isLock={documentInstance.locked}
                disableLocker={
                  !medicalEvent.isEditable ||
                  (documentInstance.type === 'farte' &&
                    (!!documentInstance.renewedAt || !!documentInstance.printedAt) &&
                    isPrescription(documentInstance.category))
                }
                colorPreset="dark"
                borderColor={DocumentCategoryColor[documentInstance.category]}
              />
            ))}
            <ValidationModal
              display={!!documentToLock}
              icon="lock"
              confirmLabel="Valider"
              title={
                documentToLock?.locked
                  ? 'Voulez vous vraiment déverrouiller ce document ? Son contenu ainsi que les valeurs de ses variables seront éditables et mises à jour avec les nouvelles données du patient'
                  : 'Voulez vous vraiment verrouiller ce document ? Son contenu ainsi que les valeurs de ses variables seront figées'
              }
              onSubmit={() => handleLockDocument()}
              onClose={() => setDocumentToLock(undefined)}
            />
            {medicalEvent.files.map((file) => {
              const type = isImageExtension(file.extension) ? FileKind.IMAGE : FileKind.FILE
              return (
                <MenuChip
                  key={file.id}
                  testId={`button-chip-file-${file.id}`}
                  onClick={handleSelectMedicalEventDocument(MedicalEventDocumentType.FILE, file.id)}
                  collapsed={file.id !== selectedFileId}
                  selected={file.id === selectedFileId}
                  onPrint={
                    isPrintableFileExtension(file.extension)
                      ? () => handlePrintFile(file)
                      : undefined
                  }
                  downloadConfig={() => handleDownloadFile(file)}
                  onDelete={medicalEvent.isDeletable ? () => handleDeleteFile(file.id) : undefined}
                  onTitleSave={
                    medicalEvent.isEditable
                      ? (title) => handleEditFileTitle(file.id, title)
                      : undefined
                  }
                  icon={MedicalEventContentTypeIcon[type]}
                  label={file.visibleName}
                  colorPreset={file.critical ? 'danger' : 'dark'}
                  disablePrintDownload={!files[file.id]}
                />
              )
            })}
          </FiltersWrapper>
          <div className="flex flex-col items-center justify-center">
            {displayAddDocumentsButton && (
              <AddContentButton
                testId="button-add-document"
                label="Document"
                isSmallScreen={isLowerThanBreakpoint}
                onClick={handleAddDocument}
              />
            )}
            <div className={styles.fileLoad}>
              <IconButton
                icon="paperClip"
                theme="primary"
                onClick={() => {
                  fileInputRef.current && fileInputRef.current.click()
                }}
              />
              <input
                ref={fileInputRef}
                autoComplete="off"
                className={styles.browse}
                onChange={handleFileUpload}
                multiple={true}
                tabIndex={-1}
                type="file"
              />
            </div>
          </div>
        </div>
      </div>
      {displayQuestionnaireFilter && <ContentFilters collapsed={!selectedDocId} />}
      <ValidationModal
        display={displayRenewModal}
        title="L'ordonnance d'origine sera verrouillée définitivement, et l'ordonnance renouvelée sera ajoutée à un nouvel évènement médical en date du jour."
        onSubmit={() => {
          selectedDocId && renewPrescription(selectedDocId)
          setDisplayRenewModal(false)
        }}
        onClose={() => {
          setDisplayRenewModal(false)
        }}
      />
      <EfficienceDmpUploadModal
        display={!!pendingDmpUpload}
        onClose={() => setPendingDmpUpload(undefined)}
        onValid={(config) => {
          if (pendingDmpUpload) {
            uploadToDMP(
              pendingDmpUpload.id,
              pendingDmpUpload.type,
              config,
              pendingDmpUpload.category,
              pendingDmpUpload.dmpDocumentId,
            )
          }
          setPendingDmpUpload(undefined)
        }}
      />
    </>
  )
}
