import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getUser } from '../../../../data/admin'
import { UserFriendlyError } from '../../../../model/Error'
import { isDefined } from '../../../../misc/functions.utilities'
import { userKeys } from './users.keys'

export const useGetUser = (userUuid?: string) => {
  const queryKey = useMemo(() => userKeys.detail(userUuid), [userUuid])

  const queryReturn = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      if (!isDefined(userUuid)) return

      const { data, ok } = await getUser(userUuid, signal)
      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          "Impossible de récupérer les données de l'utilisateur",
          `Utilisateur ${userUuid} inaccessible`,
        )
      }
      return data
    },
    enabled: isDefined(userUuid),
  })

  return queryReturn
}
