import { FC, useCallback, useMemo, useState } from 'react'
import { getV3ColorCssVariable } from '../../../design-system/colors_v3'
import { SelectOption } from '../../../model/SelectOption'
import {
  DropdownItem,
  Heading,
  Icon,
  Modal,
  ModalActions,
  ModalContent,
  RoundedButton,
  SelectInput,
  Switch,
  ThinModalLayout,
} from '../../shared'
import { EfficienceDmpUploadModalProps } from './EfficienceDmpUploadModal.model'

export const EfficienceDmpUploadModal: FC<EfficienceDmpUploadModalProps> = ({
  isUnmounting,
  healthcareSettings,
  onValid,
  onClose,
}) => {
  const [hideToPatient, setHideToPatient] = useState(false)
  const [hideToLegalRepresentative, setHideToLegalRepresentative] = useState(false)
  const [hideToHealthProfessional, setHideToHealthProfessional] = useState(false)
  const [selectSetting, setSelectedSetting] = useState<SelectOption<string>>()

  const healthcareSettingsOptions = useMemo(
    () =>
      healthcareSettings.map(({ code, description }) => ({
        value: code,
        label: description,
      })),
    [healthcareSettings],
  )

  const handleValid = useCallback(() => {
    if (selectSetting) {
      setHideToPatient(false)
      setHideToLegalRepresentative(false)
      setHideToHealthProfessional(false)
      setSelectedSetting(undefined)
      onValid({
        hideToPatient,
        hideToLegalRepresentative,
        hideToHealthProfessional,
        healthcareSettingsId: selectSetting.value,
      })
    }
  }, [selectSetting, hideToHealthProfessional, hideToLegalRepresentative, hideToPatient, onValid])

  return (
    <Modal isUnmounting={isUnmounting} onClickOutside={onClose}>
      <ThinModalLayout size="medium">
        <ModalContent>
          <Icon size="mega" icon="folderUser" color={getV3ColorCssVariable('shades', 'shade2')} />
          <Heading size={3}>Valider et envoyer le document sur le DMP</Heading>
          <SelectInput
            title="Cadre de soin"
            value={selectSetting}
            options={healthcareSettingsOptions}
            onSelect={setSelectedSetting}
            renderOption={({ label }, _, isHovered) => (
              <DropdownItem selected={isHovered}>
                <span className="whitespace-normal max-w-full">{label}</span>
              </DropdownItem>
            )}
          />
          <div className="space-y-4 w-full font-semibold text-shades-2">
            <Switch
              name="Cacher au patient"
              checked={hideToPatient}
              disabled={hideToHealthProfessional}
              onChange={setHideToPatient}
            />
            <Switch
              name="Cacher au représentant légal"
              checked={hideToLegalRepresentative}
              onChange={setHideToLegalRepresentative}
            />
            <Switch
              name="Cacher aux professionnels de santé"
              checked={hideToHealthProfessional}
              disabled={hideToPatient}
              onChange={setHideToHealthProfessional}
            />
          </div>
        </ModalContent>
        <ModalActions>
          <RoundedButton disabled={!selectSetting} onClick={handleValid} label="Valider" />
        </ModalActions>
      </ThinModalLayout>
    </Modal>
  )
}
