import { useQueryClient } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { TMP_AUTHENTICATION_ID_MAIL, AUTHENTICATION_ID_MAIL } from '../../../model/Mail'
import { selectedEmailAddressAtom } from '../../../state/mail/selectedEmailAddress.atom'
import { mailWebserviceKeys } from '../../queries/mail/webservice/webservice.keys'

export const useMailWebServiceAuthent = () => {
  const [firstStepAuthenticationId, setFirstStepAuthenticationId] = useLocalStorage<string>(
    TMP_AUTHENTICATION_ID_MAIL,
    '',
    true,
  )
  const [authenticationToken, setAuthenticationToken] = useLocalStorage<string>(
    AUTHENTICATION_ID_MAIL,
    '',
    true,
  )

  const queryClient = useQueryClient()

  const setSelectedEmailAddress = useSetAtom(selectedEmailAddressAtom)
  const navigate = useNavigate()
  const logout = useCallback(() => {
    setFirstStepAuthenticationId('')
    setAuthenticationToken('')

    // On délaie légèrement les opérations qui ne touchent pas au local storage
    // car les modifs du local storage ne sont pas instantanées et risqueraient de ne pas être exécutées
    setTimeout(() => {
      queryClient.removeQueries({ queryKey: mailWebserviceKeys.mailAddresses })
      setSelectedEmailAddress(null)
      navigate('/authenticate/mail')
    }, 0)
  }, [
    navigate,
    queryClient,
    setAuthenticationToken,
    setFirstStepAuthenticationId,
    setSelectedEmailAddress,
  ])

  return {
    firstStepAuthenticationId,
    setFirstStepAuthenticationId,
    authenticationToken,
    setAuthenticationToken,
    logout,
  }
}
