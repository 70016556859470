import { useMutation } from '@tanstack/react-query'
import { finalizeAuthenticationMail } from '../../../../data/mail'
import { subCodeTokenExpired } from '../../../../misc/mail.utilities'
import { UserFriendlyError } from '../../../../model/Error'
import {
  MailWebServiceLoginOtpFormFields,
  MailWebServiceLoginResponse,
} from '../../../../model/Mail'
import { mailErrorsMessage, mailErrorsTitle } from '../mail.errors'

interface FinalizeAuthenticationVariables extends MailWebServiceLoginOtpFormFields {
  tmpAuthenticationId: string
}

export const useFinalizeMailAuthentication = () => {
  const mutation = useMutation<MailWebServiceLoginResponse, Error, FinalizeAuthenticationVariables>(
    {
      mutationFn: async ({ tmpAuthenticationId, ...variables }) => {
        const { ok, data, status } = await finalizeAuthenticationMail(
          variables,
          tmpAuthenticationId,
        )

        // middleware ? prochaine mr
        if ((!ok && data && subCodeTokenExpired(data, status)) || status === 401) {
          // disconnect ? prochaine mr
          throw new UserFriendlyError('error', 'Échec de la connexion', 'Veuillez réessayer')
        }

        if (!ok || !data) {
          throw new UserFriendlyError(
            'error',
            mailErrorsTitle.connexionError,
            mailErrorsMessage.checkCredentials,
          )
        }

        return data
      },
    },
  )

  return mutation
}
