import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FeaturesType } from '@follow/cdk'
import {
  ActionModalLayout,
  InputWrapper,
  Modal,
  RoundedButton,
  Switch,
  TooltipWrapper,
} from '../../shared'
import { SendFileModalProps } from './SendFileModal.model'
import { DocumentInstanceListItem } from '../../../model/DocumentInstance'
import { RecipientContact } from '../../../model/Contact'
import classNames from 'classnames'
import { InformationAlert } from '../../user'
import { emailRegex } from '../../../misc/regex'
import { isDefined } from '../../../misc/functions.utilities'
import { useCurrentPatient } from '../../../hooks/utils'
import { getPatientMssEmail } from '../../../misc/patient.utilities'
import { isExtensionAllowed } from '../../../misc/files.utilities'
import { useUserEnabledFeature } from '../../../hooks/utils/user'
import styles from './sendFileModal.module.scss'

export const SendFileModal: FunctionComponent<SendFileModalProps> = ({
  isUnmounting,
  currentMedicalEvent,
  onSubmit,
  onClose,
}) => {
  const [, setSearchParams] = useSearchParams()
  const { currentPatient } = useCurrentPatient()
  const mssSmtpEnabled = useUserEnabledFeature(FeaturesType.mssSmtp)
  const isMailAttachmentsEnabled = useUserEnabledFeature(FeaturesType.mailAttachments)

  const listOfFarteDocumentIds =
    currentMedicalEvent?.documents
      .filter((document) => document.type === 'farte')
      .map((document) => document.id) || []

  const [selectedObservation, setSelectedObservation] = useState(false)
  const [selectedFilesAttachments, setSelectedFilesAttachments] = useState<number[]>([])
  const [selectedDocumentsAttachments, setSelectedDocumentsAttachments] =
    useState<number[]>(listOfFarteDocumentIds)

  const handleClose = useCallback(() => {
    onClose()
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams)
      updatedParams.delete('sending')
      return updatedParams
    })
  }, [onClose, setSearchParams])

  const selectedDocuments = useMemo(
    () =>
      currentMedicalEvent?.documents.filter(({ id }) =>
        selectedDocumentsAttachments.includes(id),
      ) || [],
    [currentMedicalEvent, selectedDocumentsAttachments],
  )

  const preselectedMssEmails: string[] = useMemo(() => {
    // Pour chaque document sélectionné, on récupère la liste des adresses emails MSS des contacts destinaires
    // Puis on assemble tout dans un seul array en supprimant les doublons
    const emailAddressesGroupedByDocuments = selectedDocuments.map(
      (document: DocumentInstanceListItem) =>
        document.contacts
          .filter((contact) => contact.mainRecipient || contact.copyRecipient)
          .map(({ mssEmail }) => {
            return mssEmail && emailRegex.test(mssEmail.emailAddress)
              ? mssEmail?.emailAddress
              : null
          })
          .filter(isDefined),
    )

    const flattenedEmailAddresses = emailAddressesGroupedByDocuments.flat()

    const patientMssEmail =
      currentPatient?.inseeNumber && getPatientMssEmail(currentPatient.inseeNumber)

    const emailAdresses = patientMssEmail
      ? [patientMssEmail, ...flattenedEmailAddresses]
      : flattenedEmailAddresses

    // On supprime les doublons
    return [...new Set(emailAdresses)]
  }, [currentPatient?.inseeNumber, selectedDocuments])

  const handleSend = useCallback(() => {
    const documents = currentMedicalEvent?.documents.filter((currenDocument) =>
      selectedDocumentsAttachments.includes(currenDocument.id),
    )
    onSubmit(selectedObservation, preselectedMssEmails, documents, selectedFilesAttachments)
    onClose()
  }, [
    currentMedicalEvent?.documents,
    onClose,
    onSubmit,
    preselectedMssEmails,
    selectedDocumentsAttachments,
    selectedFilesAttachments,
    selectedObservation,
  ])

  // Pour chaque document sélectionné, on récupère la liste des contacts destinaires ayant une adresse email MSS absente ou invalide
  // Puis on assemble tout dans un seul array en supprimant les doublons
  // En triant par prénom / nom pour éviter les comportement étranges lors de la sélection de documents
  const recipientWithInvalidMssEmail: RecipientContact[] = useMemo(() => {
    const recipientContactsGroupedByDocuments = selectedDocuments.map(
      (document: DocumentInstanceListItem) =>
        document.contacts
          .filter((contact) => contact.mainRecipient || contact.copyRecipient)
          .map((contact) => {
            return !contact.mssEmail || !emailRegex.test(contact.mssEmail.emailAddress)
              ? contact
              : null
          })
          .filter(isDefined),
    )

    const recipientContacts = recipientContactsGroupedByDocuments
      .flat()
      .sort((contactA, contactB) =>
        `${contactA.firstName} ${contactA.familyName}` >
        `${contactB.firstName} ${contactB.familyName}`
          ? 1
          : -1,
      )

    // On supprime les doublons
    return [...new Map(recipientContacts.map((contact) => [contact.id, contact])).values()]
  }, [selectedDocuments])

  const warningMessage = useMemo(() => {
    if (recipientWithInvalidMssEmail.length === 0) return null
    const isSingular = recipientWithInvalidMssEmail.length === 1
    const contactNames = recipientWithInvalidMssEmail
      .map(({ firstName, familyName }) => `${firstName} ${familyName}`)
      .join(', ')

    return isSingular ? (
      <span>
        L'adresse MSS du contact <b>{contactNames}</b> est manquante/erronée. Cette adresse ne sera
        pas pré-remplie dans le champ "destinataire"
      </span>
    ) : (
      <span>
        Les adresses MSS des contacts <b>{contactNames}</b> sont manquantes/erronées. Ces adresses
        ne seront pas pré-remplies dans le champ "destinataire"
      </span>
    )
  }, [recipientWithInvalidMssEmail])

  return (
    <Modal isUnmounting={isUnmounting} onClickOutside={handleClose}>
      <ActionModalLayout
        title="Sélectionnez le contenu à envoyer"
        onClose={handleClose}
        actions={
          <div className="grid grid-flow-col gap-4 w-full justify-end">
            <RoundedButton
              label="Annuler"
              size="micro"
              theme="transparent-dark"
              onClick={handleClose}
            />
            <RoundedButton label="Envoyer" size="micro" onClick={handleSend} />
          </div>
        }
      >
        <div className={classNames('bg-gray-50 p-4 pb-6 overflow-y-scroll', styles.mainContainer)}>
          {recipientWithInvalidMssEmail.length > 0 && (
            <div className={styles.warningMessage}>
              <InformationAlert type="warn">{warningMessage}</InformationAlert>
            </div>
          )}

          <div className="mb-6">
            <span className="text-shades-4 text-xs">Corps de message</span>
            <InputWrapper
              disabled={!currentMedicalEvent?.observation}
              disabledLabel="Vous n'avez pas saisi d'observations dans l'évènement"
            >
              <Switch
                name="Observations"
                checked={selectedObservation}
                onChange={() => setSelectedObservation((oldValue) => !oldValue)}
                disabled={!currentMedicalEvent?.observation}
              ></Switch>
            </InputWrapper>
          </div>
          {isMailAttachmentsEnabled && (
            <>
              <div className="mb-6">
                {currentMedicalEvent?.documents && (
                  <>
                    <span className="text-shades-4 text-xs">Documents Follow (seront liés)</span>
                    {currentMedicalEvent?.documents
                      .filter((document) => document.type === 'farte')
                      .map((document) => (
                        <InputWrapper key={document.id}>
                          <Switch
                            key={document.id}
                            name={document.title}
                            checked={selectedDocumentsAttachments.includes(document.id)}
                            onChange={(checked) =>
                              setSelectedDocumentsAttachments((oldValue) =>
                                checked
                                  ? [...oldValue, document.id]
                                  : oldValue.filter((currentId) => currentId !== document.id),
                              )
                            }
                          />
                        </InputWrapper>
                      ))}
                  </>
                )}
              </div>
              <div className="mb-6">
                {currentMedicalEvent?.files && (
                  <>
                    <span className="text-shades-4 text-xs">Autres documents</span>
                    {currentMedicalEvent?.documents
                      .filter((document) => document.type === 'pdf')
                      .map((document) => (
                        <InputWrapper key={document.id}>
                          <Switch
                            name={document.title}
                            checked={selectedDocumentsAttachments.includes(document.id)}
                            onChange={(checked) =>
                              setSelectedDocumentsAttachments((oldValue) =>
                                checked
                                  ? [...oldValue, document.id]
                                  : oldValue.filter((currentId) => currentId !== document.id),
                              )
                            }
                          />
                        </InputWrapper>
                      ))}
                    {currentMedicalEvent?.files.map((file) => {
                      const forbiddenExtension = !isExtensionAllowed(file.extension, mssSmtpEnabled)
                      return (
                        <InputWrapper key={file.id}>
                          <TooltipWrapper
                            pointerDirection="top"
                            content="L'envoi de ce type de fichier est impossible"
                            display={forbiddenExtension}
                          >
                            <Switch
                              name={file.visibleName}
                              checked={selectedFilesAttachments.includes(file.id)}
                              disabled={forbiddenExtension}
                              onChange={(checked) =>
                                setSelectedFilesAttachments((oldValue) =>
                                  checked
                                    ? [...oldValue, file.id]
                                    : oldValue.filter((currentId) => currentId !== file.id),
                                )
                              }
                            />
                          </TooltipWrapper>
                        </InputWrapper>
                      )
                    })}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </ActionModalLayout>
    </Modal>
  )
}
