import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router'
import { compose } from 'recompose'
import { LoadingPage } from './components/shared'
import withUsurpedUserInitialization from './misc/WithUsurpedUserInitialization'
import { User } from './model/User'
import { MedicalEventPage } from './pages/MedicalEventPage'
import {
  PatientsList,
  PatientPage,
  PatientDetailsPage,
  PatientTransversalNavigationPage,
} from './pages/patient'

import { RootState } from './store'
import {
  currentUserSelector,
  enabledFeaturesPreferencesSelector,
  getMe,
  loggedUserSelector,
} from './store/domain/me'
import { getFullName, isAdmin, isDoctor, isExtDsm } from './misc/user.utilities'
import { Roles } from './model/Roles'
import { MailLayout, MainLayout } from './layouts'
import { LayoutErrorBoundary } from './errors'
import { hasSpecificRoles } from './misc/roles.utilities'
import { MailLoginPage, MailPage } from './pages/mail'
import { usurpedSelector } from './store/ui/adminUsers'
import { ConnectedUserContext } from './misc/auth.utilities'
import BottomPanelWrapper from './containers/BottomPanelWrapper'
import { LegalPage } from './pages/LegalPage'
import { TaskPage } from './pages/TaskPage'
import { EfficienceIframe } from './components/dmp'
import { Features } from './model/Preference'
import { InsiConsultIframe } from './components/insi/InsiConsultIframe'
import { RouteGuardWithRedirection, RouteGuardWithFallback } from './components/navigation'
import { PatientLayout } from './components/layout'
import { useNotificationSubscriber } from './hooks/utils/notifications/useNotificationSubscriber.hook'
import { StellairOAuthPage } from './pages/authent'
import { MailEmptyPage } from './pages/mail/MailEmptyPage/MailEmptyPage.component'
import { useDoctolibSubscribers } from './hooks/utils/doctolib'
import { usePatientInformationsSubscribers } from './hooks/utils/patientInformations/usePatientInformationsSubscribers.hook'
import { usePatientHealthDataSubscribers } from './hooks/utils/patientHealthdata/usePatientHealthDataSubscribers.hook'
import { PatientTitle } from './components/layout/PatientTitle'
import { AccountRouter, AdminRouter, ManagerRouter } from './router'
import { useWidthObserver } from './hooks/utils'
import { breakMedium } from './misc/responsive'
import { LargeScreenAccessiblePageOnly } from './pages/LargeScreenAccessiblePageOnly'
import { useMaintainMailAuth } from './hooks/queries/mail'

interface ConnectedViewsContainerStateProps {
  currentUser: User | null
  loggedUser: User | null
  usurpedUser: User | null
  userEnabledFeatures: Features | null
}

interface ConnectedViewsContainerDispatchProps {
  getMe: typeof getMe
}

type ConnectedViewsContainerConnectedViewsProps = ConnectedViewsContainerDispatchProps &
  ConnectedViewsContainerStateProps

const ConnectedViewsContainer: FC<ConnectedViewsContainerConnectedViewsProps> = ({
  currentUser,
  loggedUser,
  usurpedUser,
  userEnabledFeatures,
  getMe,
}) => {
  useDoctolibSubscribers()
  usePatientInformationsSubscribers()
  usePatientHealthDataSubscribers()
  useEffect(() => {
    getMe()
  }, [getMe])

  useNotificationSubscriber(loggedUser)
  useMaintainMailAuth()
  const isLowerThanBreakpoint = useWidthObserver(breakMedium)

  useEffect(() => {
    if (currentUser) {
      document.title = `${getFullName(currentUser)} - Follow`
    }
    return () => {
      document.title = 'Follow - App'
    }
  }, [currentUser])

  if (!currentUser) {
    return (
      <Routes>
        <Route path="*" element={<MainLayout />}>
          <Route path="*" element={<LoadingPage />} />
        </Route>
      </Routes>
    )
  }

  const isCurrentUserDoctor = hasSpecificRoles(currentUser.roles, [Roles.DOCTOR])
  const isCurrentUserAdmin = isAdmin(currentUser)
  const isLoggedUserExtDsm = isExtDsm(loggedUser)

  const isMssEnabled = isDoctor(currentUser) && !!currentUser.preferences.enabledFeatures.mail

  return (
    <ConnectedUserContext.Provider
      value={{
        loggedUser: loggedUser,
        currentUser: currentUser,
        usurpedUser: usurpedUser,
      }}
    >
      <BottomPanelWrapper />
      <Routes>
        <Route path="*" element={<MainLayout />}>
          <Route
            path="patients"
            element={
              <RouteGuardWithRedirection
                condition={isCurrentUserDoctor && !isLoggedUserExtDsm}
                redirect="/managers"
              />
            }
          >
            <Route index element={<PatientsList />} />
            <Route path="new" element={<PatientDetailsPage />} />
            <Route path=":patientId" element={<PatientTitle />}>
              <Route path="details" element={<PatientDetailsPage />} />
              <Route
                path="browse_events"
                element={
                  <RouteGuardWithFallback
                    condition={!isLowerThanBreakpoint}
                    fallbackComponent={
                      <LargeScreenAccessiblePageOnly page="Navigation Transverse" />
                    }
                  />
                }
              >
                <Route path="*" element={<PatientTransversalNavigationPage />} />
              </Route>
              <Route path="*" element={<PatientLayout />}>
                <Route index element={<PatientPage />} />
                <Route path="medicalEvent">
                  <Route path=":medicalEventId" element={<MedicalEventPage />} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route
            path="tasks"
            element={
              <RouteGuardWithRedirection
                condition={isCurrentUserDoctor && !isLoggedUserExtDsm}
                redirect="/managers"
              />
            }
          >
            <Route index element={<TaskPage />} />
          </Route>

          <Route
            path="managers/*"
            element={
              <RouteGuardWithFallback
                condition={!isLowerThanBreakpoint}
                fallbackComponent={<LargeScreenAccessiblePageOnly page="Manager" />}
              />
            }
          >
            <Route path="*" element={<ManagerRouter />} />
          </Route>

          <Route path="account/*" element={<AccountRouter />} />

          <Route path="legal" element={<LegalPage />} />

          <Route
            path="admin"
            element={
              <RouteGuardWithRedirection
                condition={isCurrentUserAdmin || isLoggedUserExtDsm}
                redirect="/managers"
              />
            }
          >
            <Route path="*" element={<AdminRouter />} />
          </Route>

          <Route
            path="authenticate/mail"
            element={<RouteGuardWithRedirection condition={isMssEnabled} redirect="/patients" />}
          >
            <Route index element={<MailLoginPage />} />
          </Route>
          <Route
            path="mail"
            element={<RouteGuardWithRedirection condition={isMssEnabled} redirect="/patients" />}
          >
            <Route path="inbox" element={<MailLayout />}>
              <Route index element={<MailEmptyPage />} />
              <Route path=":mailId" element={<MailPage />} />
            </Route>
          </Route>
          <Route path="stellair/oauth2/redirect" element={<StellairOAuthPage />} />
          <Route path="error" element={<LayoutErrorBoundary />} />
          <Route
            path="*"
            element={
              <Navigate
                to={isCurrentUserDoctor && !isLoggedUserExtDsm ? '/patients' : '/managers'}
              />
            }
          />
        </Route>
      </Routes>
      {userEnabledFeatures?.dmpInApp && <EfficienceIframe />}
      {userEnabledFeatures?.insiValidation === 'insiValidationInApp' && <InsiConsultIframe />}
    </ConnectedUserContext.Provider>
  )
}

const mapStateToProps = (state: RootState): ConnectedViewsContainerStateProps => ({
  loggedUser: loggedUserSelector(state),
  currentUser: currentUserSelector(state),
  usurpedUser: usurpedSelector(state),
  userEnabledFeatures: enabledFeaturesPreferencesSelector(state),
})

const mapDispatchToProps: ConnectedViewsContainerDispatchProps = {
  getMe,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose<ConnectedViewsContainerConnectedViewsProps, {}>(
  withUsurpedUserInitialization,
  withConnect,
)(ConnectedViewsContainer)
