import { connect } from 'react-redux'
import { RootState } from '../../../store'
import { PatientInfosStoreProps, PatientInfosDispatchProps } from './PatientInfos.model'
import { enabledFeaturesPreferencesSelector } from '../../../store/domain/me'
import { medicalEventUiActions } from '../../../store/ui/medicalEvents'
import { PatientInfos } from './PatientInfos.component'
import { clipboardActions } from '../../../store/clipboard'

const mapStateToProps = (state: RootState): PatientInfosStoreProps => ({
  enabledFeatures: enabledFeaturesPreferencesSelector(state),
})
const mapDispatchToProps: PatientInfosDispatchProps = {
  selectMedicalEventDocument: medicalEventUiActions.selectMedicalEventDocument,
  copyTextToClipboard: clipboardActions.copyTextToClipboard,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(PatientInfos)

export { withConnect as PatientInfos }
