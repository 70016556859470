import { useAtom } from 'jotai'
import { MAIL_CONNECTION_LOOP_DELAY } from '../../../model/Mail'
import { selectedEmailAddressAtom } from '../../../state/mail/selectedEmailAddress.atom'
import { useGetMailAdresses } from './webservice'

// En webservice le token meurt assez vite, forçant l'utilisateur à se reconnecter
// Pour ça, on maintient artificiellement le token en vie en l'utilisant toutes les 15 min
export const useMaintainMailAuth = () => {
  const [selectedEmailAddress] = useAtom(selectedEmailAddressAtom)

  useGetMailAdresses({
    enabled: !!selectedEmailAddress,
    refetchInterval: MAIL_CONNECTION_LOOP_DELAY,
  })
}
