import uuidv4 from 'uuid/v4'
import { Message, MessageOptions } from '../store/message'
import { MessageSeverity } from './Notification'

export class UserFriendlyError extends Error {
  constructor(
    public type: MessageSeverity,
    message: string,
    public description?: string,
    public options?: MessageOptions,
  ) {
    super(message)
  }

  getMessage(): Message {
    const id: string = this.options?.id ?? uuidv4()
    return {
      type: this.type,
      title: this.message,
      message: this.description,
      id,
      ...this.options,
    }
  }
}

export interface ApiErrorContent {
  detail: string
}
