import { useFormikContext } from 'formik'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { CitiesSearch, CountriesSearch } from '../../../location'
import { FormikTextInput, Icon, TooltipWrapper } from '../../../shared'
import { City, Country } from '../../../../model/Contact'
import { DisabledINSITooltipWrapper } from '../DisabledINSITootipWrapper'
import { PatientFormFields } from '../PatientForm.model'
import { PatientBirthPlaceCodeBlockProps } from './PatientBirthPlaceCodeBlock.model'
import { useResetINSIStatusHook } from '../useResetINSIStatus.hook'
import { inseeNumberValidator } from '../../../../misc/inseeNumber.utils'
import { isINSIValidated } from '../../../../misc/patient.utilities'
import { useGetInfiniteCities } from '../../../../hooks/queries/cities'
import { useGetInfiniteCountries } from '../../../../hooks/queries/countries'
import { isDefined } from '../../../../misc/functions.utilities'
import styles from './PatientBirthPlaceCodeBlock.module.scss'

const DEFAULT_COUNTRY_SEARCH = 'FRANCE'

export const PatientBirthPlaceCodeBlock: FC<PatientBirthPlaceCodeBlockProps> = ({
  patient,
  disabled,
}) => {
  const [selectedCountry, setSelectedCountry] = useState<Country>()
  const [selectedCity, setSelectedCity] = useState<City>()
  const [searchBirthPlaceCodewithFilter, setSearchBirthPlaceCodeWithFilter] = useState(false)
  const [countrySearch, setCountrySearch] = useState<string | null>(null)
  const [citySearch, setCitySearch] = useState<string | null>(null)
  const [debouncedCitySearch, setDebouncedCitySearch] = useState<string | null>(null)
  const [debouncedCountrySearch, setDebouncedCountrySearch] = useState<string | null>(null)

  const defaultCountryName = patient?.birthPlaceCountry ?? DEFAULT_COUNTRY_SEARCH
  const { countryList } = useGetInfiniteCountries({
    filters: { search: debouncedCountrySearch ?? defaultCountryName },
  })

  const defaultCityName = patient?.birthPlaceCity ?? ''
  const { cityList } = useGetInfiniteCities({
    filters: { search: debouncedCitySearch ?? defaultCityName },
  })

  const { values, setFieldValue } = useFormikContext<PatientFormFields>()

  const resetINSIStatus = useResetINSIStatusHook()

  const handleDebouncedCity = useMemo(
    () =>
      debounce((city: string) => {
        setDebouncedCitySearch(city)
      }, 500),
    [],
  )

  const handleDebouncedCountry = useMemo(
    () =>
      debounce((country: string) => {
        setDebouncedCountrySearch(country)
      }, 500),
    [],
  )

  const handleCitySearch = useCallback(
    (city: string) => {
      setCitySearch(city)
      handleDebouncedCity(city)
    },
    [handleDebouncedCity],
  )

  const handleCountrySearch = useCallback(
    (country: string) => {
      setCountrySearch(country)
      handleDebouncedCountry(country)
    },
    [handleDebouncedCountry],
  )

  const handleSelectCountry = useCallback(
    (country?: Country) => {
      setSelectedCountry(country)
      handleCountrySearch(country?.name ?? '')
      if (country?.inseeCode) {
        setFieldValue('birthPlaceCode', country.inseeCode.toString())
      }
    },
    [handleCountrySearch, setFieldValue],
  )

  const handleSelectCity = useCallback(
    (city?: City) => {
      setSelectedCity(city)
      handleCitySearch(city?.name ?? '')
      if (city?.inseeCode) {
        setFieldValue('birthPlaceCode', city.inseeCode.toString())
      }
    },
    [handleCitySearch, setFieldValue],
  )

  const isDisabled = disabled

  useEffect(() => {
    if (countrySearch === null && !isDefined(selectedCountry) && countryList.length > 0) {
      const defaultCountry = countryList.find(({ name }) => name === defaultCountryName)
      if (isDefined(defaultCountry)) {
        handleSelectCountry(defaultCountry)
      }
    }
  }, [countryList, countrySearch, defaultCountryName, handleSelectCountry, selectedCountry])

  useEffect(() => {
    if (citySearch === null && !isDefined(selectedCity) && cityList.length > 0) {
      const defaultCity = cityList.find(({ name }) => name === defaultCityName)
      if (isDefined(defaultCity)) {
        handleSelectCity(defaultCity)
      }
    }
  }, [cityList, citySearch, defaultCityName, handleSelectCity, selectedCity])

  const isDefaultBirthPlaceCode = useMemo(
    () => values.birthPlaceCode === '99999',
    [values.birthPlaceCode],
  )

  const handleDefaultBirthPlaceCode = useCallback(
    () => setFieldValue('birthPlaceCode', '99999'),
    [setFieldValue],
  )

  return (
    <>
      {searchBirthPlaceCodewithFilter && !inseeNumberValidator(values.inseeNumber) && (
        <>
          <CountriesSearch
            disabled={isDisabled}
            countries={countryList}
            search={countrySearch ?? defaultCountryName}
            selectedCountry={selectedCountry}
            onSearch={handleCountrySearch}
            onSelectCountry={handleSelectCountry}
          />
          {selectedCountry?.isoCode === 'FR' && (
            <CitiesSearch
              disabled={isDisabled}
              cities={cityList}
              search={citySearch ?? defaultCityName}
              selectedCity={selectedCity}
              onSearch={handleCitySearch}
              onSelectCity={handleSelectCity}
            />
          )}
        </>
      )}
      <DisabledINSITooltipWrapper display={isINSIValidated(values.identityStatus)}>
        <TooltipWrapper
          pointerDirection="top"
          content="Le code naissance est extrait du matricule INS"
          display={
            !isINSIValidated(values.identityStatus) && inseeNumberValidator(values.inseeNumber)
          }
          size="small"
        >
          <div>
            <FormikTextInput
              type="text"
              fieldName="birthPlaceCode"
              label="Code lieu de naissance (INSEE) *"
              disabled={isDisabled || searchBirthPlaceCodewithFilter}
              colorPreset="light"
              onChange={resetINSIStatus}
            />
            {!searchBirthPlaceCodewithFilter && (
              <div className={styles.birthPlaceCodeHelpContainer}>
                <Icon icon="infoCircle" size="pico" />
                <div className={styles.birthPlaceCodeTextContainer}>
                  Entrez
                  <button
                    className={styles.addbirthPlaceCode}
                    onClick={handleDefaultBirthPlaceCode}
                    data-test-id="button-add-default_birthplace_code"
                    disabled={isDefaultBirthPlaceCode || disabled}
                  >
                    99999
                  </button>
                  si inconnu.
                </div>
              </div>
            )}
            {!isDisabled && (
              <button
                onClick={() => setSearchBirthPlaceCodeWithFilter((current) => !current)}
                className={styles.birthPlaceCodeSearchButton}
                type="button"
              >
                {searchBirthPlaceCodewithFilter
                  ? 'Saisir le code lieu de naissance manuellement'
                  : 'Effectuer une recherche avancée par pays et par ville'}
              </button>
            )}
          </div>
        </TooltipWrapper>
      </DisabledINSITooltipWrapper>
    </>
  )
}
