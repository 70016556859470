import React, { FunctionComponent } from 'react'
import { breakSmall } from '../../../../../misc/responsive'
import { PatientPageHeaderHeaderProps } from './PatientPageHeader.model'
import { getAge, getAgeWithUnit } from '../../../../../misc/date.utilities'
import { MIN_MAJOR_AGE, Sex } from '../../../../../model/Patient'
import { Icon, Tag } from '../../../../shared'
import { ColorsV2, ColorsV2TagBackground } from '../../../../../design-system/colors_v2'
import styles from './PatientPageHeader.module.scss'
import { getDisplayedName } from '../../../../../misc/patient.utilities'
import { useWidthObserver } from '../../../../../hooks/utils'

export const PatientPageHeader: FunctionComponent<PatientPageHeaderHeaderProps> = ({ patient }) => {
  const isLowerThanBreakpoint = useWidthObserver(breakSmall)

  const ageText = !isLowerThanBreakpoint ? `(${getAgeWithUnit(patient.birthDate)})` : ''
  const { firstname, lastname } = getDisplayedName(patient)
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        {patient.sex !== Sex.UNKNOWN && (
          <Icon
            icon={patient.sex === Sex.MALE ? 'male' : 'female'}
            color={ColorsV2.dsWhite}
            size={isLowerThanBreakpoint ? 'micro' : 'normal'}
          />
        )}
      </div>
      {`${firstname} ${lastname} ${ageText}`}
      <div className={styles.deceasedAndMinorLabelContainer}>
        {getAge(patient.birthDate) < MIN_MAJOR_AGE && (
          <Tag color={ColorsV2TagBackground.dsTag03}>Mineur·e</Tag>
        )}
        {patient.isDeceased && <Tag color={ColorsV2TagBackground.dsTag09}>Décédé·e</Tag>}
      </div>
    </div>
  )
}
