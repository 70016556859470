import { useMutation, useQueryClient } from '@tanstack/react-query'
import { postUser } from '../../../../data/admin'
import { User } from '../../../../model/User'
import { UserFriendlyError } from '../../../../model/Error'
import { addValid } from '../../../../store/message'
import { useActionDispatch } from '../../../utils'
import { useNavigate } from 'react-router-dom'
import { userKeys } from './users.keys'

export const usePostUser = (redirectAfterCreation?: boolean) => {
  const valid = useActionDispatch(addValid)
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const queryReturn = useMutation({
    mutationFn: async (variables: Partial<User>) => {
      const { data, ok } = await postUser(variables)
      if (!data || !ok) {
        throw new UserFriendlyError('error', "La création d'utilisateur a échoué")
      }
      return data
    },

    onSuccess: (data) => {
      valid('Utilisateur créé avec succès')
      if (redirectAfterCreation) {
        navigate(`/admin/users/${data.id}`)
      }
      queryClient.removeQueries({ queryKey: userKeys.all })
      queryClient.setQueryData(userKeys.detail(data.id), data)
    },
  })

  return queryReturn
}
