import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { questionnaireInstancesKeys } from './questionnaireInstances.keys'
import { getQuestionnaireInstanceScore } from '../../../data/questionnaireInstances/getQuestionnaireInstanceScore'
import { UserFriendlyError } from '../../../model/Error'
import { isDefined } from '@follow/cdk'

interface QuestionnaireScoreParams {
  documentId: number
  questionnaireId: number
  enabled: boolean
}

export const useGetQuestionnaireInstanceScore = ({
  documentId,
  questionnaireId,
  enabled,
}: QuestionnaireScoreParams) => {
  const queryKey = useMemo(
    () => questionnaireInstancesKeys.score(questionnaireId),
    [questionnaireId],
  )

  const queryReturn = useQuery({
    queryKey,

    queryFn: async () => {
      const { data, ok } = await getQuestionnaireInstanceScore(documentId, questionnaireId)

      if (!isDefined(data) || !ok) {
        throw new UserFriendlyError(
          'error',
          `Impossible de récupérer le score du questionnaire ${questionnaireId}`,
          `Une erreur technique s'est produite`,
        )
      }
      return data
    },
    enabled,
  })

  return { query: queryReturn }
}
