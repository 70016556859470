import React, { FunctionComponent, useState } from 'react'
import classNames from 'classnames/bind'
import {
  DropdownContainer,
  DropdownItem,
  Icon,
  IconButton,
  TagCollection,
  TooltipWrapper,
} from '../../../../../shared'
import { EventDetailsTooltip } from './EventDetailsTooltip'
import styles from './Header.module.scss'
import { ColorsV2 } from '../../../../../../design-system/colors_v2'

interface HeaderProps {
  medicalEventType: string
  title: string
  ownerName?: string
  creatorName?: string
  tagIds: ReadonlyArray<string>
  locked: boolean
  pinned: boolean
  onPinMedicalEvent: () => void
  onClick?: () => void
  onEditMedicalEvent?: () => void
  onDeleteMedicalEvent?: () => void
  onDuplicateMedicalEventDocuments?: () => void
  onOwnerChange?: () => void
}

const cx = classNames.bind(styles)

export const Header: FunctionComponent<HeaderProps> = ({
  title,
  ownerName,
  creatorName,
  tagIds,
  medicalEventType,
  locked,
  pinned,
  onPinMedicalEvent,
  onClick,
  onEditMedicalEvent,
  onDeleteMedicalEvent,
  onDuplicateMedicalEventDocuments,
  onOwnerChange,
}) => {
  const clickable = !!onClick
  const [openOptions, setOpenOptions] = useState(false)
  const hasOwnerOrCreatorName = !!ownerName || !!creatorName

  return (
    <div
      className={cx(styles.container, {
        [medicalEventType]: true,
        clickable,
      })}
      onClick={onClick}
      title={clickable ? `Editer l'événement` : undefined}
    >
      <div className={styles.headerText}>
        <div className={styles.title}>
          {locked && (
            <span className="mr-2">
              <Icon icon={'lock'} size="nano" color={ColorsV2.dsWhite} />
            </span>
          )}
          {title}
          {hasOwnerOrCreatorName && (
            <TooltipWrapper
              content={<EventDetailsTooltip ownerName={ownerName} creatorName={creatorName} />}
              pointerDirection="top"
              textAlign="left"
            >
              <div>
                <IconButton icon="infoCircle" theme="transparent-light" size="micro" noBorder />
              </div>
            </TooltipWrapper>
          )}
        </div>
        <div className="flex flex-row items-center justify-end">
          {pinned && (
            <TooltipWrapper content="Désépingler">
              <div>
                <IconButton
                  icon="pin"
                  theme="transparent"
                  noBorder
                  onClick={(e) => {
                    e.stopPropagation()
                    onPinMedicalEvent()
                  }}
                />
              </div>
            </TooltipWrapper>
          )}
          {(onEditMedicalEvent ||
            onDeleteMedicalEvent ||
            onDuplicateMedicalEventDocuments ||
            (onEditMedicalEvent && !pinned)) && (
            <>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  setOpenOptions(true)
                }}
                title=""
                icon="more"
                theme="transparent-light"
                size="micro"
                testId="button-medical_event-actions"
              />
              <div className="absolute top-2.5 right-13 opacity-100">
                <DropdownContainer
                  direction="right"
                  opened={openOptions}
                  closeDropdown={() => setOpenOptions(false)}
                  verticalScroll={false}
                >
                  {onOwnerChange && (
                    <DropdownItem
                      onClick={(e) => {
                        e.stopPropagation()
                        setOpenOptions(false)
                        onOwnerChange()
                      }}
                      testId="button-update-owner-medical-event"
                    >
                      <span>Changer de propriétaire</span>
                    </DropdownItem>
                  )}
                  {!pinned && (
                    <DropdownItem
                      onClick={(e) => {
                        e.stopPropagation()
                        onPinMedicalEvent()
                        setOpenOptions(false)
                      }}
                      testId="button-pin-medical-event"
                    >
                      <span>Épingler l'événement</span>
                    </DropdownItem>
                  )}
                  {onEditMedicalEvent && (
                    <DropdownItem
                      onClick={(e) => {
                        e.stopPropagation()
                        onEditMedicalEvent()
                      }}
                      testId="button-edit-medical_event"
                    >
                      <span>Editer l'événement</span>
                    </DropdownItem>
                  )}
                  {onDuplicateMedicalEventDocuments && (
                    <DropdownItem
                      onClick={(e) => {
                        e.stopPropagation()
                        onDuplicateMedicalEventDocuments()
                        setOpenOptions(false)
                      }}
                      testId="button-duplicate-documents"
                    >
                      <span>Dupliquer document(s)</span>
                    </DropdownItem>
                  )}
                  {onDeleteMedicalEvent && (
                    <DropdownItem
                      onClick={(e) => {
                        e.stopPropagation()
                        setOpenOptions(false)
                        if (window.confirm('Voulez-vous vraiment supprimer cet événement?')) {
                          onDeleteMedicalEvent()
                        }
                      }}
                      testId="button-delete-medical_event"
                    >
                      <span>Supprimer</span>
                    </DropdownItem>
                  )}
                </DropdownContainer>
              </div>
            </>
          )}
        </div>
      </div>
      <div>
        <div className={cx(styles.tags, 'space-x-2')}>
          <TagCollection ids={tagIds} />
        </div>
      </div>
    </div>
  )
}
