import { getDisplayedName, RENDERABLE_FILE_EXTENSION } from '@follow/cdk'
import { Patient } from '../model/Patient'
import { formatFr } from './date.utilities'
import {
  FileKind,
  PrintableFileExtension,
  PRINTABLE_FILE_EXTENSION,
  RenderableFileExtension,
} from '../model/File'

const IMAGE_EXTENSIONS = ['png', 'jpg', 'jpeg'] // A completer
const AUDIO_EXTENSIONS = ['m4a', 'wav', 'mp3', 'ogg']
export const WEB_SERVICES_ACCEPTED_EXTENSIONS =
  'image/jpeg, image/jpg, image/tiff, application/pdf, application/rtf, text/plain, text/xml'

const MAX_UPLOADABLE_FILE_SIZE = 50

const MEGA_BYTES = 1024 * 1024

export const getFileSizeInMegaBytes = (size: number) => size / MEGA_BYTES

export const isImageExtension = (extension: string) =>
  IMAGE_EXTENSIONS.includes(extension.toLowerCase())

export const isAudioExtension = (extension: string) =>
  AUDIO_EXTENSIONS.includes(extension.toLowerCase())

export const isXMLExtension = (extension: string) => extension.toLowerCase() === 'xml'

export const isPdfExtension = (extension: string) => extension.toLowerCase() === 'pdf'

export const isDisplayableFile = (extension: string) =>
  isImageExtension(extension) || isPdfExtension(extension)

export const isUnsupportedExtension = (extension: string) =>
  !isImageExtension(extension) &&
  !isPdfExtension(extension) &&
  !isAudioExtension(extension) &&
  !isXMLExtension(extension)

export const isUploadableToServer = (file: File) =>
  getFileSizeInMegaBytes(file.size) < MAX_UPLOADABLE_FILE_SIZE

export const removeBase64Prefix = (base64Str: string) => {
  const prefixPattern = /^data:.*;base64,/
  return prefixPattern.test(base64Str) ? base64Str.replace(prefixPattern, '') : base64Str
}

export const isValidBase64 = (base64Str: string) => {
  try {
    window.atob(base64Str)
  } catch (e) {
    return false
  }
  return true
}

export const removeExtension = (filename: string) => {
  const lastIndex = filename.lastIndexOf('.')

  if (lastIndex === -1) {
    return filename
  }

  return filename.substring(0, lastIndex)
}

export const getExtension = (filename: string) => {
  const lastIndex = filename.lastIndexOf('.')

  if (lastIndex === -1 || lastIndex === 0) {
    return ''
  }

  return filename.substring(lastIndex + 1)
}

export const allFilesValid = (acceptedTypes: string, files: File[]) =>
  acceptedTypes === '' || files.every((file) => acceptedTypes.includes(file.type))

export const getFileKind = (extension: string) =>
  isImageExtension(extension) ? FileKind.IMAGE : FileKind.FILE

export const getRenderedPdfFileName = (patient: Patient | null) => {
  const displayedName = patient ? getDisplayedName(patient) : null
  const patientName = displayedName ? `${displayedName.firstname}_${displayedName.lastname}_` : ''

  return `${patientName}lot_${formatFr(new Date(), 'yyyy-MM-dd-hh-mm-ss')}.pdf`
}

export const isRenderableFileExtension = (
  extension: string,
): extension is RenderableFileExtension =>
  (RENDERABLE_FILE_EXTENSION as ReadonlyArray<string>).includes(extension)

export const isPrintableFileExtension = (extension: string): extension is PrintableFileExtension =>
  (PRINTABLE_FILE_EXTENSION as ReadonlyArray<string>).includes(extension)

export const isExtensionAllowed = (extension: string, mssSmtpEnabled?: boolean) =>
  !!mssSmtpEnabled || WEB_SERVICES_ACCEPTED_EXTENSIONS.includes(extension)

export const addExtensionIfMissing = (filename: string, extension: string) => {
  if (!filename || !extension) {
    return filename
  }

  const extensionSuffix = `.${extension}`
  if (filename.endsWith(extensionSuffix)) {
    return filename
  } else {
    return `${filename}${extensionSuffix}`
  }
}
