import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { MailLoginCard, MailLoginForm, MailLoginOtpForm } from '../../../components/mail'
import { MailOTPMethod } from '../../../components/mail/login/MailLoginForm/MailLoginForm.model'
import {
  MailWebServiceLoginFormFields,
  MailWebServiceLoginOtpFormFields,
} from '../../../model/Mail'
import { isDoctor } from '../../../misc/user.utilities'
import { MailNoAddresses } from '../../../components/mail/login/MailNoAddresses'
import {
  useFinalizeMailAuthentication,
  useGetMailAdresses,
  useInitiateMailAuthentication,
} from '../../../hooks/queries/mail'
import { useAtom } from 'jotai'
import { mailLoginRedirect } from '../../../state/mail'
import { useNavigate } from 'react-router-dom'
import { RESET } from 'jotai/utils'
import { useUserEnabledFeature } from '../../../hooks/utils/user'
import { FeaturesType } from '@follow/cdk'
import { ConnectedUserContext } from '../../../misc/auth.utilities'
import { useMailWebServiceAuthent } from '../../../hooks/utils/mail/useMailWebServiceAuthent.hook'
import { LoadingPage } from '../../../components/shared'
import { selectedEmailAddressAtom } from '../../../state/mail/selectedEmailAddress.atom'
import { useQueryClient } from '@tanstack/react-query'
import { mailWebserviceKeys } from '../../../hooks/queries/mail/webservice/webservice.keys'

export const MailLoginPage: FunctionComponent = () => {
  const queryClient = useQueryClient()
  const [otpMethod, setOtpMethod] = useState(MailOTPMethod.SMS)

  const {
    firstStepAuthenticationId,
    setFirstStepAuthenticationId,
    authenticationToken,
    setAuthenticationToken,
  } = useMailWebServiceAuthent()

  const navigate = useNavigate()
  const [redirectAfterLogin, setRedirectAfterLogin] = useAtom(mailLoginRedirect)

  const { mutate: initiateAuthentication } = useInitiateMailAuthentication()
  const { mutate: finalizeAuthentication } = useFinalizeMailAuthentication()
  const {
    query: { isFetching: isMailAddressListLoading },
  } = useGetMailAdresses({ enabled: !!authenticationToken })
  const [selectedEmailAddress, setSelectedEmailAddress] = useAtom(selectedEmailAddressAtom)

  const { currentUser } = useContext(ConnectedUserContext)
  const isMssSmtpActive = useUserEnabledFeature(FeaturesType.mssSmtp)

  function handleLogin(values: MailWebServiceLoginFormFields) {
    initiateAuthentication(values, {
      onSuccess: (data) => {
        setOtpMethod(values.otpMethod)
        setFirstStepAuthenticationId(data.authenticationId)
      },
      onSettled: () => {
        setSelectedEmailAddress(null)
        queryClient.removeQueries({ queryKey: mailWebserviceKeys.mailAddresses })
      },
    })
  }

  function handleLoginOtp({ otpPassword }: MailWebServiceLoginOtpFormFields) {
    if (otpPassword && firstStepAuthenticationId) {
      finalizeAuthentication(
        { otpPassword, tmpAuthenticationId: firstStepAuthenticationId },
        {
          onSettled: () => {
            setFirstStepAuthenticationId('')
          },
          onSuccess: (data) => {
            setAuthenticationToken(data.authenticationId)
          },
        },
      )
    }
  }

  useEffect(() => {
    if (authenticationToken && selectedEmailAddress) {
      const redirect = redirectAfterLogin
      setRedirectAfterLogin(RESET)
      navigate(redirect)
    }
  }, [
    authenticationToken,
    navigate,
    redirectAfterLogin,
    selectedEmailAddress,
    setRedirectAfterLogin,
  ])

  function handleRetry() {
    setFirstStepAuthenticationId('')
  }

  return (
    <div className="flex flex-row h-screen w-full overflow-hidden">
      {isMssSmtpActive && isDoctor(currentUser) && !currentUser.mailizEmailAddress ? (
        <MailLoginCard>
          <MailNoAddresses />
        </MailLoginCard>
      ) : (
        <>
          {isMailAddressListLoading && !selectedEmailAddress ? (
            <LoadingPage />
          ) : (
            <MailLoginCard>
              {!firstStepAuthenticationId ? (
                <MailLoginForm onSubmit={handleLogin} currentUser={currentUser} />
              ) : (
                <MailLoginOtpForm
                  onSubmit={handleLoginOtp}
                  onRetry={handleRetry}
                  otpMethod={otpMethod}
                  firstStepToken={firstStepAuthenticationId}
                />
              )}
            </MailLoginCard>
          )}
        </>
      )}
    </div>
  )
}
